import { Icon } from "@iconify/react";
import pieChart2Fill from "@iconify/icons-eva/pie-chart-2-fill";
import peopleFill from "@iconify/icons-eva/people-fill";
import eyeFill from "@iconify/icons-eva/eye-fill";
import funnelFill from "@iconify/icons-eva/funnel-fill";
import gridFill from "@iconify/icons-eva/grid-fill";
import questionMarkCircleFill from "@iconify/icons-eva/question-mark-circle-fill";
import phoneCallFill from "@iconify/icons-eva/phone-call-fill";
// ----------------------------------------------------------------------

const getIcon = (name) => <Icon icon={name} width={22} height={22} />;

const sidebarConfig = [
  {
    title: "dashboard",
    path: "/dashboard/app",
    icon: (
      <img src={"/static/icons/dashboard.svg"} width={22} height={22} alt="" />
    ),
    activeIcon: (
      <img
        src={"/static/icons/dashboard_active.svg"}
        width={22}
        height={22}
        alt=""
      />
    ),
  },
  {
    title: "Leads",
    path: "/dashboard/leads",
    tour: "reactour__leads",
    icon: <img src={"/static/icons/leads.svg"} width={22} height={22} alt="" />,
    activeIcon: (
      <img
        src={"/static/icons/leads_active.svg"}
        width={22}
        height={22}
        alt=""
      />
    ),
  },
  {
    title: "Clients",
    path: "/dashboard/projects",
    tour: "reactour__clients",
    icon: (
      <img src={"/static/icons/client.svg"} width={22} height={22} alt="" />
    ),
    activeIcon: (
      <img
        src={"/static/icons/client_active.svg"}
        width={22}
        height={22}
        alt=""
      />
    ),
  },
  {
    title: "profile",
    path: "/dashboard/myProfile",
    tour: "reactour__profile",
    icon: (
      <img src={"/static/icons/profile.svg"} width={22} height={22} alt="" />
    ),
    activeIcon: (
      <img
        src={"/static/icons/profile_active.svg"}
        width={22}
        height={22}
        alt=""
      />
    ),
  },
  // {
  //   title: "portfolio",
  //   path: "/dashboard/portfolio",
  //   icon: (
  //     <img src={"/static/icons/portfolio.svg"} width={22} height={22} alt="" />
  //   ),
  //   activeIcon: (
  //     <img
  //       src={"/static/icons/portfolio_active.svg"}
  //       width={22}
  //       height={22}
  //       alt=""
  //     />
  //   ),
  // },
  {
    title: "FAQs",
    path: "/dashboard/faq",
    tour: "reactour__faq",
    icon: <img src={"/static/icons/faq.svg"} width={22} height={22} alt="" />,
    activeIcon: (
      <img src={"/static/icons/faq_active.svg"} width={22} height={22} alt="" />
    ),
  },
  {
    title: "Support",
    path: "/dashboard/contact_us",
    tour: "reactour__support",
    icon: (
      <img src={"/static/icons/contact.svg"} width={22} height={22} alt="" />
    ),
    activeIcon: (
      <img
        src={"/static/icons/contact_active.svg"}
        width={22}
        height={22}
        alt=""
      />
    ),
  },
  {
    title: "RA Leads",
    path: "/dashboard/ra",
    icon: <img src={"/static/icons/leads.svg"} width={22} height={22} alt="" />,
    activeIcon: (
      <img
        src={"/static/icons/leads_active.svg"}
        width={22}
        height={22}
        alt=""
      />
    ),
  },
];

export default sidebarConfig;
