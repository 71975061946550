import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchRaLeadByIdThunk,
  putNotInterestedthunk,
} from "../../store/slices/ra/ra.slice";
import { useParams, Outlet, useNavigate } from "react-router-dom";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Box,
  Stack,
  Avatar,
  Paper,
  Button,
  TextField,
  IconButton,
  Input,
} from "@mui/material";
import CustomTabs from "../../components/common/Tabs";
import clsx from "clsx";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import GroupsIcon from "@mui/icons-material/Groups";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import { DateTime } from "luxon";

import { capitalCase } from "../../utils/change-case";
import { useStyles } from "./RaDetails.theme";

function RaDetails() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const params = useParams();
  const lead = useSelector(({ ra }) => ra.lead);
  useEffect(() => {
    dispatch(fetchRaLeadByIdThunk(params.leadId));
  }, []);

  const handleNotIntrested = () => {

    const vendor_lead_id = lead?.ra_lead_id;
    
    dispatch(putNotInterestedthunk(vendor_lead_id));
  };

  return (
    <>
      <Grid item xs={12} sm={12} md={12}>
        <Card mb={5}>
          <CardContent>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              mb={5}
            >
              <Typography
                gutterBottom
                variant="h5"
                sx={{ marginBottom: 0, fontSize: { xs: "16px", sm: "16px" } }}
              >
                RA Leads Details
              </Typography>
              <Box sx={{ display: "flex", gap: "10px", alignItems: "center" }}>
                <Box
                  component="span"
                  display="inline"
                  className={clsx(classes.badge_blue, classes.badge)}
                  sx={{ fontSize: { xs: "16px", sm: "16px" } }}
                >
                  {capitalCase(lead?.lead_status)}
                </Box>
                {lead?.lead_status !== "not_interested" && (
                  <>
                    <Box>
                      <Button
                        variant="outlined"
                        color="secondary"
                        onClick={handleNotIntrested}
                      >
                        Not Interested
                      </Button>
                    </Box>
                  </>
                )}
              </Box>
            </Stack>
            {lead?.skills?.length > 0 &&
              lead?.skills?.map((data, idx) => {
                return (
                  <Grid container>
                    <Grid item mb={6} xs={12}>
                      <table style={{ width: "70%", borderSpacing: "16px" }}>
                        <tbody>
                          <tr>
                            <td style={{ width: "20%" }}>
                              <Typography
                                variant="h5"
                                sx={{
                                  // color: "#a4b0be",
                                  fontWeight: "600",
                                }}
                              >
                                Skill Details:
                              </Typography>
                            </td>
                            <td colSpan={3} style={{ width: "40%" }}>
                              <Typography variant="h5" sx={{ fontWeight: "600" }}>
                                {data.skill_name}
                              </Typography>
                            </td>
                           
                          </tr>
                          <tr>
                            <td>
                              <Typography
                                variant="body1"
                                sx={{
                                  // color: "#a4b0be",
                                  fontWeight: "600",
                                }}
                              >
                                Must have skills:
                              </Typography>
                            </td>
                            <td>
                              <Typography sx={{ fontWeight: "400" }}>
                                {data?.must_have?.map(item => item.name).join(", ")}
                              </Typography>
                            </td>
                            <td>
                              <Typography
                                variant="body1"
                                sx={{
                                  // color: "#a4b0be",
                                  fontWeight: "600",
                                }}
                              >
                                Good to have skills:
                              </Typography>
                            </td>
                            <td>
                              <Typography sx={{ fontWeight: "400" }}>
                                {data?.nice_to_have?.map(item => item.name).join(", ")}
                              </Typography>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <Typography
                                variant="body1"
                                sx={{
                                  // color: "#a4b0be",
                                  fontWeight: "600",
                                }}
                              >
                                Budget:
                              </Typography>
                            </td>
                            <td>
                              <Typography sx={{ fontWeight: "400" }}>
                                {data?.emb_budget || "-"}
                              </Typography>
                            </td>
                             <td style={{ width: "20%" }}>
                              <Typography
                                variant="body1"
                                sx={{
                                  // color: "#a4b0be",
                                  fontWeight: "600",
                                }}
                              >
                                Year of Experience :
                              </Typography>
                            </td>
                            <td style={{ width: "40%" }}>
                              <Typography sx={{ fontWeight: "400" }}>
                                {data?.year_of_exp} Year(s)
                              </Typography>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <Typography
                                variant="body1"
                                sx={{
                                  // color: "#a4b0be",
                                  fontWeight: "600",
                                }}
                              >
                                JD File:
                              </Typography>
                            </td>
                            <td>
                              <Typography sx={{ fontWeight: "400" }}>
                                {data?.emb_jd_file || "-"}
                              </Typography>
                            </td>
                            <td>
                              <Typography
                                variant="body1"
                                sx={{
                                  // color: "#a4b0be",
                                  fontWeight: "600",
                                }}
                              >
                                Resource Count:
                              </Typography>
                            </td>
                            <td colSpan={3}>
                              <Typography
                                variant="body1"
                                sx={{
                                  // color: "#a4b0be",
                                  fontWeight: "400",
                                }}
                              >
                                {data?.resource_count}
                              </Typography>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <Typography
                                variant="body1"
                                sx={{
                                  // color: "#a4b0be",
                                  fontWeight: "600",
                                }}
                              >
                                JD Description:
                              </Typography>
                            </td>
                            <td colSpan={3}>
                              <Typography sx={{ fontWeight: "400" }}>
                                {data?.emb_job_description || "-"}
                              </Typography>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      {/* <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: 12,
                        }}
                      >
                        <Typography
                          variant="body1"
                          sx={{
                            // color: "#a4b0be",
                            fontWeight: "600",
                            paddingBottom: "10px",
                            display: "flex",
                            alignItems: "center",
                            gap: 1,
                          }}
                        >
                          Skill Details:
                          <Typography sx={{ fontWeight: "400" }}>
                            {data.skill}
                          </Typography>
                        </Typography>
                        <Typography
                          variant="body1"
                          sx={{
                            // color: "#a4b0be",
                            fontWeight: "600",
                            paddingBottom: "10px",
                            display: "flex",
                            alignItems: "center",
                            gap: 1,
                          }}
                        >
                          <CalendarTodayIcon sx={{ fontSize: "15px" }} /> 
                          Experiance:
                          <Typography sx={{ fontWeight: "400" }}>
                            {data?.year_of_exp} Years
                          </Typography>
                        </Typography>
                      </Box>

                      <Stack
                        direction="row"
                        spacing={4}
                        justifyContent="space-start"
                      >
                        <Typography
                          variant="body1"
                          sx={{
                            fontSize: { xs: "15px" },
                            color: "#a4b0be",
                            fontWeight: "600",
                            display: "flex",
                            alignItems: "center",
                            gap: 1,
                          }}
                        >
                          <GroupsIcon sx={{ fontSize: "15px" }} /> Resource
                          Count : {data?.resource_count}
                        </Typography>
                      </Stack>
                      <Typography
                        variant="body1"
                        sx={{
                          color: "#a4b0be",
                          fontWeight: "400",
                          paddingBottom: "10px",
                        }}
                      >
                        Job Description: {data?.job_description}
                      </Typography>
                      {data?.jd_file_url ? (
                        <Typography
                          variant="body1"
                          sx={{
                            color: "#a4b0be",
                            fontWeight: "400",
                            paddingBottom: "10px",
                          }}
                        >
                          <a
                            style={{
                              color: "#a4b0be",
                              fontWeight: "400",
                              paddingBottom: "10px",
                            }}
                            target="_black"
                            href={data?.jd_file_url}
                          >
                            JD File: {data?.jd_filename}
                          </a>
                        </Typography>
                      ) : (
                        <></>
                      )} */}
                    </Grid>
                  </Grid>
                );
              })}

            <Typography
              variant="body1"
              sx={{
                fontSize: { xs: "16px" },
                color: "#a4b0be",
                fontWeight: "600",
                textAlign: "right",
              }}
            >
              <AccessTimeIcon sx={{ fontSize: "14px" }} /> Created Date:{" "}
              {DateTime.fromISO(lead.created_at).toFormat("DD")}
            </Typography>
          </CardContent>
        </Card>
      </Grid>
    </>
  );
}

export default RaDetails;
