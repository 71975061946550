import {
  addressesPath,
  bankDetailsPath,
  basicInfoPath,
  benchStrength,
  certification,
  expertiseBreakup,
  founderInfo,
  poc,
  portfolioPath,
  saasChannelPartner,
  servicePath,
  services,
  socialMediaRating,
  techStackForte,
  testimonials,
  uploadPortfolioPath,
  verificationPath,
} from "../../utils/constant";
import BankDetails from "./BankDetails";
import BasicInfo from "./BasicInfo";
import ProjectTable from "./ProjectTable";
import POC from './POC';
import FounderprofileTable from "./FounderprofileTable";
import BusinessVerification from "./Business_Verification";
import SassProfileTable from "./SassProfileTable";
import Certification from "./Certification";
import ExpertiseBreakup from "./ExpertiseBreakup";
import SocialMediaRating from "./SocialMediaRating";
import BenchStrengthTableForm from "./BenchStrengthTableForm";
import ProductCodeTable from "./ProductCodeTable";
import PastWorkTable from "./PastWorkTable";
import Testimonials from "./Testimonial";
import UploadPortfolio from "../Porfolio/UploadPortfolio";
import TechStackForte from "./TechStackForte";
import VendorServices from "./VendorServices";

export const routesLink = [ 

  // {
  //   label: "Profile",
  //   value: basicInfoPath,
  //   icon: "/static/icons/overview.svg",
  //   activeIcon: "/static/icons/overview_active.svg",
  // },
  {
    label: "Business Information",
    value: basicInfoPath,
    icon: "/static/icons/overview.svg",
    activeIcon: "/static/icons/overview_active.svg",
    childTabs: [
      {
        label: "Info",
        value: basicInfoPath, 
        icon: "/static/icons/address.svg",
        activeIcon: "/static/icons/address_active.svg",
        components: <BasicInfo />,
      },
      {
        label: "Address",
        value: addressesPath,
        icon: "/static/icons/address.svg",
        activeIcon: "/static/icons/address_active.svg",
        components: <ProjectTable />,
      },
      {
        label: "POC",
        value: poc,
        icon: "/static/icons/POC.svg",
        activeIcon: "/static/icons/POC_active.svg",
        components:<POC/>
      },
      {
        label: "Founders Information",
        value: founderInfo,
        icon: "/static/icons/expertise.svg",
        activeIcon: "/static/icons/expertise_active.svg",
        components:<FounderprofileTable/>
      },
    ],
  },
  {
    label: "Bank",
    value: bankDetailsPath,
    icon: "/static/icons/bank_details.svg",
    activeIcon: "/static/icons/bank_details_active.svg",
    components: <BankDetails />,
    childTabs: [
      {
        label: "Details",
        value: bankDetailsPath,
        icon: "/static/icons/bank_details.svg",
        activeIcon: "/static/icons/bank_details_active.svg",
        components: <BankDetails />,
      },
      {
        label: "Verification",
        value: verificationPath,
        icon: "/static/icons/verification.svg",
        activeIcon: "/static/icons/verification_active.svg",
        components:  <BusinessVerification />,
      },
    ],
  },
  {
    label: "Portfolio",
    value: servicePath,
    icon: "/static/icons/service.svg",
    activeIcon: "/static/icons/service_active.svg",
    childTabs: [
      {
        label: "Services",
        value: services,
        icon: "/static/icons/service.svg",
        activeIcon: "/static/icons/service_active.svg",
        components: <VendorServices />,
      },
      // {
      //   label: "Product Code",
      //   value: servicePath,
      //   icon: "/static/icons/service.svg",
      //   activeIcon: "/static/icons/service_active.svg",
      //   components: <ProductCodeTable />,
      // },
      {
        label: "Past Work",
        value: portfolioPath,
        icon: "/static/icons/portfolio.svg",
        activeIcon: "/static/icons/portfolio_active.svg",
        components: <PastWorkTable />,
      },
      {
        label: "Corporate Deck",
        value: uploadPortfolioPath,
        icon: "/static/icons/upload_portfolio.svg",
        activeIcon: "/static/icons/upload_portfolio_active.svg",
        components: <UploadPortfolio />,
      },
      {
        label: "Testimonials",
        value: testimonials,
        icon: "/static/icons/expertise.svg",
        activeIcon: "/static/icons/expertise_active.svg",
        components: <Testimonials />,
      },
    ],
  },

  {
    label: "Affiliations",
    value: saasChannelPartner,
    icon: "/static/icons/expertise.svg",
    activeIcon: "/static/icons/expertise_active.svg",
    components: <BankDetails />,
    childTabs: [
      {
        label: "Saas Channel Partner",
        value: saasChannelPartner,
        icon: "/static/icons/expertise.svg",
        activeIcon: "/static/icons/expertise_active.svg",
        components: <SassProfileTable />,
      },
      {
        label: "Certifications",
        value: certification,
        icon: "/static/icons/expertise.svg",
        activeIcon: "/static/icons/expertise_active.svg",
        components: <Certification />,
      },
    ],
  },
  {
    label: "Expertise Breakup",
    value: expertiseBreakup,
    icon: "/static/icons/expertise.svg",
    activeIcon: "/static/icons/expertise_active.svg",
    components:<ExpertiseBreakup/>
  },

  {
    label: "Platform Review",
    value: socialMediaRating,
    icon: "/static/icons/expertise.svg",
    activeIcon: "/static/icons/expertise_active.svg",
    components:<SocialMediaRating/>
  },

  {
    label: "Resources",
    value: techStackForte,
    icon: "/static/icons/expertise.svg",
    activeIcon: "/static/icons/expertise_active.svg",
    childTabs: [
      {
        label: "Tech Stack Forte",
        value: techStackForte,
        icon: "/static/icons/expertise.svg",
        activeIcon: "/static/icons/expertise_active.svg",
        components:<TechStackForte/>
      },
      {
        label: "Bench Strength",
        value: benchStrength,
        icon: "/static/icons/expertise.svg",
        activeIcon: "/static/icons/expertise_active.svg",
        components:<BenchStrengthTableForm/>
      },
    ],
  },
];
