import React, { useEffect } from "react";
import { Typography, Grid, Stack, Box, Button } from "@mui/material";

import { useStyles as formStyles } from ".././../components/Form/Form.theme";
// components
import Element from "../../components/Form/Element";
import { inputType } from "../../utils/enum";
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import "../FormData/upload.css";
import { uploadCVValidation } from "../../utils/validation";

import { makeStyles } from "@mui/styles";
import { addCVsThunk, fetchAllCVsThunk } from "../../store/slices/ra/ra.slice";
import { useState } from "react";
import useResourceBifercation from "../../hooks/useBifercation";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import useTeckStack from "../../hooks/useTechStack";

export const useStyles = makeStyles({
  dropdownIconCss: {
    "& svg": {
      bottom: "14px !important",
    },
  },
  "@media (max-width:600px)": {
    dropdownIconCss: {
      padding: "0px  !important",
    },
  },
});

const INIT_STATE = {
  applicant_name: "",
  skill: "",
  year_of_exp: "",
  vendor_budget: "",
  description: "",
};

export default function UploadCvs({ hidePortfolioModalCheck, raId, props, singleBenchStrengthDataInfo=null }) {
  const dispatch = useDispatch();
  const classes = formStyles();
  const [file, setFile] = React.useState(null);
  const cvs = useSelector(({ ra }) => ra.cvs);
  const [resourceBifercation, getObj] = useResourceBifercation();

  const [cvError, setCvError] = useState("");

  const handleSubmit = (values) => {
    if (!file) {
      setCvError("CV File is required");
      return;
    }
    const formData = new FormData();
    formData.append("applicant_name", values.applicant_name);
    formData.append("skill", values.skill);
    formData.append("year_of_exp", Number(values.year_of_exp));
    formData.append("vendor_budget", Number(values.vendor_budget));
    // formData.append("description", values.description);
    formData.append("cv_filename", file.name);
    formData.append("cv_file", file);
    formData.append("work_location_type", "remote");
    // if (props) {
    //   var data = {
    //     applicant_name: values.applicant_name,
    //     skill: values.skill,
    //     year_of_exp: values.year_of_exp,
    //     vendor_budget: values.vendor_budget,
    //     description: values.description,
    //   };
    //   // TODO: Thunk needs to be changed
    //   return dispatch(
    //     updatePortfolioThunk({
    //       id: props.id,
    //       data,
    //     })
    //   )
    //     .unwrap()
    //     .then(hidePortfolioModalCheck);
    // }
    // // TODO: Thunk needs to be changed
    // dispatch(addPortfolioThunk(values)).unwrap().then(hidePortfolioModalCheck);
    dispatch(addCVsThunk({ raId, formData }))
      .unwrap()
      .then(hidePortfolioModalCheck);
  };

  function handleFileChange(event) {
    setFile(event.target.files[0]);
    setCvError("");
  }

  function getFilename() {
    var content = "";
    if (file.name?.length > 20) {
      content = file?.name?.substring(0, 20) + "...";
    } else content = file.name;

    if (file.url) {
      return (
        <p className={classes.filename}>
          <a
            href={file.url}
            target="_blank"
            style={{ textDecoration: "none", color: "grey" }}
          >
            {content}
          </a>
        </p>
      );
    }
    return <p className={classes.filename}>{content}</p>;
  }

  useEffect(() => {
    dispatch(fetchAllCVsThunk(raId));
  }, [raId]);

  function handleResourceChange(e) {}

  const [techStack, setTeckStack, getTechStackObj] = useTeckStack(
    singleBenchStrengthDataInfo?.resource_bifurcation?.id
  );

  return (
    <Grid container>
      <Grid item md={12}>
        <Typography gutterBottom variant="h5" component="div" mt={2}>
          Upload CV's
        </Typography>

        <Formik
          initialValues={
            props
              ? {
                  ...props,
                }
              : INIT_STATE
          }
          onSubmit={handleSubmit}
          validationSchema={uploadCVValidation}
        >
          {({
            values,
            handleChange,
            handleBlur,
            touched,
            handleSubmit,
            errors,
          }) => (
            <form onSubmit={handleSubmit}>
              <Box sx={{ margin: "20px 0" }}>
                <Grid container spacing={2}>
                  <Grid item md={12} pb={0}>
                    <Element
                      label="Applicant Name"
                      inputProps={{
                        type: "text",
                        placeholder: "Enter Applicant Name",
                        onChange: handleChange,
                        onBlur: handleBlur,
                        name: "applicant_name",
                      }}
                      eletype={inputType.input}
                      value={values.applicant_name}
                      errorText={
                        touched.applicant_name && errors.applicant_name
                      }
                    />
                  </Grid>
                  <Grid item md={12} pb={1}>
                    <Element
                      label="Resouce Bifuercation"
                      inputProps={{
                        name: "resource_value",
                        onChange: (e) => {
                          const id = e.target.value;
                          setTeckStack(id);
                          handleChange({
                            target: {
                              name: `resource_value`,
                              value: id,
                            },
                          });
                        },
                        onBlur: handleBlur,
                      }}
                      value={values.resource_value}
                      options={resourceBifercation}
                      errorText={
                        touched.resource_value && errors.resource_value
                      }
                      eletype={inputType.select}
                      icons={
                        <ArrowDropDownIcon
                          sx={{
                            position: "absolute",
                            right: 20,
                            bottom: 24,
                          }}
                        />
                      }
                    />
                  </Grid>
                  <Grid item md={12} pb={0}>
                    <Element
                      label="Skill"
                      inputProps={{
                        type: "text",
                        placeholder: "Enter Skill",
                        onChange: handleChange,
                        onBlur: handleBlur,
                        name: "skill",
                      }}
                      eletype={inputType.select}
                      options={techStack}
                      value={values.skill}
                      errorText={touched.skill && errors.skill}
                    />
                  </Grid>

                  <Grid item md={12} pb={0}>
                    <Element
                      label="Budget*"
                      inputProps={{
                        type: "text",
                        placeholder: "Vendor Budget",
                        onChange: handleChange,
                        onBlur: handleBlur,
                        name: "vendor_budget",
                      }}
                      eletype={inputType.input}
                      value={values.vendor_budget}
                      errorText={touched.vendor_budget && errors.vendor_budget}
                    />
                  </Grid>

                  <Grid item md={12} pb={2}>
                    <Element
                      label="Year Of Experience"
                      inputProps={{
                        type: "text",
                        placeholder: "Enter YOE",
                        onChange: handleChange,
                        onBlur: handleBlur,
                        name: "year_of_exp",
                      }}
                      eletype={inputType.input}
                      value={values.year_of_exp}
                      errorText={touched.year_of_exp && errors.year_of_exp}
                    />
                  </Grid>

                  {/* <Grid item md={12} pb={0}>
                    <Element
                      label="Description"
                      inputProps={{
                        type: "text",
                        placeholder: "Description if any",
                        onChange: handleChange,
                        onBlur: handleBlur,
                        name: "description",
                      }}
                      eletype={inputType.input}
                      value={values.description}
                      errorText={touched.description && errors.description}
                    />
                  </Grid> */}

                  <Grid item md={12} pb={2}>
                    <label className={classes.formLabel}>CV</label>
                    <div class="file-upload">
                      <div class="file-select">
                        <div class="file-select-button" id="fileName">
                          Upload
                        </div>
                        <div class="file-select-name" id="noFile">
                          Browse File...
                        </div>
                        <input
                          type="file"
                          name="chooseFile"
                          className={classes.formControl}
                          onChange={handleFileChange}
                          accept=".pdf,.docx,.doc"
                        />
                      </div>
                    </div>
                    {file?.name && (
                      <p className={classes.filename}>{getFilename()}</p>
                    )}
                    <div>
                      {cvError && (
                        <span className={classes.formErrorStyle}>
                          {cvError}
                        </span>
                      )}
                    </div>
                  </Grid>
                </Grid>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "right",
                  margin: "20px 0",
                }}
              >
                <Stack direction="row" spacing={1}>
                  <Button variant="outlined" type="submit">
                    Save
                  </Button>
                </Stack>
              </Box>
            </form>
          )}
        </Formik>
        <hr />
      </Grid>
    </Grid>
  );
}
