import React, { useState } from "react";
import {
  Typography,
  Container,
  Card,
  Box,
  Grid,
  Stack,
  Button,
} from "@mui/material";
import "./upload.css";
// components
import {
  verificationValidation,
  verificationValidationWithMSME,
} from "../../utils/validations/vendor.validation";
import useForm from "../../hooks/useForm";
import Element from "../../components/Form/Element";
import { inputType } from "../../utils/enum";
import { updateBusinessProfileThunk } from "../../store/slices/vendor/vendor.slice";
import { useDispatch, useSelector } from "react-redux";
import useBusinessId from "../../hooks/useBusinessId";
import BuisnessVerificationLoader from "../../components/Skeletons/BuisnessVerificationLoader";

export default function Business_Verification() {
  const businessId = useBusinessId();
  const dispatch = useDispatch();
  const { profileData } = useSelector(({ vendor }) => vendor);
  const [MSMESwitch, setMSMESwitch] = useState(
    profileData.msme_type ? "on" : "off"
  );
  const { values, errors, touched, handleChange, handleBlur, handleSubmit } =
    useForm({
      initialValues: {
        pan_number: profileData.pan_number,
        pan_file: profileData.pan_file,
        gst_number: profileData.gst_number,
        gst_file: profileData.gst_file,
        aadhaar_number: profileData.aadhaar_number,
        aadhaar_file: profileData.aadhaar_file,
        incorporation_number: profileData.incorporation_number,
        incorporation_file: profileData.incorporation_file,
        msme_reg_number: profileData.msme_reg_number,
        msme_reg_file: profileData.msme_reg_file,
        address_proof: profileData.address_proof,
        nda_file_verified: profileData.nda_file_verified ? "on" : "off",
        nda_file: profileData.nda_file,
        msme_type: profileData.msme_type,
        // maseAvailableSwitch: profileData.msme_type ? "on" : "off",
        // maseAvailableSwitch: profileData.maseAvailableSwitch ? "on" : "off",
        maseAvailableSwitch: profileData.maseAvailableSwitch,
        pfchallanFile: profileData?.pf_challan_file,
        udyog_number: profileData?.udyog_number,
      },
      onSubmit: onSubmit,
      validationSchema:
        MSMESwitch === "on"
          ? verificationValidationWithMSME
          : verificationValidation,
    });

  function onSubmit(data) {
    const pastData = {
      pan_number: profileData.pan_number,
      pan_file: profileData.pan_file,
      gst_number: profileData.gst_number,
      gst_file: profileData.gst_file,
      aadhaar_number: profileData.aadhaar_number,
      aadhaar_file: profileData.aadhaar_file,
      incorporation_number: profileData.incorporation_number,
      incorporation_file: profileData.incorporation_file,
      msme_reg_number: profileData.msme_reg_number,
      msme_reg_file: profileData.msme_reg_file,
      address_proof: profileData.address_proof,
      nda_file_verified: profileData.nda_file_verified === "on" ? true : false,
      nda_file: profileData.nda_file,
      msme_type: profileData.msme_type,
      // maseAvailableSwitch: profileData.msme_type ? "on" : "off",
      maseAvailableSwitch: profileData.maseAvailableSwitch ? "on" : "off",
      pfchallanFile: profileData?.pf_challan_file,
      udyog_number: profileData?.udyog_number,
    };

    dispatch(
      updateBusinessProfileThunk({
        id: businessId,
        data: {
          ...data,
          nda_file_verified: data.nda_file_verified === "on" ? true : false,
        },
        past_data: pastData,
      })
    );
  }
  return (
    <Container>
      <Box>
        <Grid container spacing={2} mt={5}>
          <Grid item md={12}>
            <Card sx={{ minHeight: "420px", py: 2, px: 1 }}>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                m={2}
              >
                <Typography variant="h4" gutterBottom>
                  Business Verification Details
                </Typography>
              </Stack>
              <form onSubmit={handleSubmit}>
                <Grid container spacing={2} p={3}>
                  <Grid item md={6} pb={2}>
                    <Element
                      label="Business Pan"
                      inputProps={{
                        type: "text",
                        placeholder: "Enter Business Pan",
                        onChange: handleChange,
                        onBlur: handleBlur,
                        name: "pan_number",
                      }}
                      eletype={inputType.input}
                      value={values.pan_number}
                      errorText={touched.pan_number && errors.pan_number}
                      disabled={
                        profileData.profile_stage ===
                        "sent_for_accounts_approval"
                          ? true
                          : false
                      }
                    />
                  </Grid>
                  <Grid item md={6} p={3}>
                    <Element
                      label="Upload Business Pan"
                      inputProps={{
                        type: "text",

                        onChange: handleChange,
                        onBlur: handleBlur,
                        name: "pan_file",
                      }}
                      extraArguments={{
                        upload_type: "pan_file",
                        vendor_profile_id: `${businessId}`,
                      }}
                      eletype={inputType.file}
                      value={values.pan_file}
                      errorText={touched.pan_file && errors.pan_file}
                      disabled={
                        profileData.profile_stage ===
                        "sent_for_accounts_approval"
                          ? true
                          : false
                      }
                    />
                  </Grid>
                  <Grid item md={6} p={3}>
                    <Element
                      label="GST Number"
                      inputProps={{
                        type: "text",
                        placeholder: "Enter GST Number",
                        onChange: handleChange,
                        onBlur: handleBlur,
                        name: "gst_number",
                      }}
                      eletype={inputType.input}
                      value={values.gst_number}
                      errorText={touched.gst_number && errors.gst_number}
                      disabled={
                        profileData.profile_stage ===
                        "sent_for_accounts_approval"
                          ? true
                          : false
                      }
                    />
                  </Grid>
                  <Grid item md={6} p={3}>
                    <Element
                      label="Upload GST file"
                      inputProps={{
                        type: "text",

                        onChange: handleChange,
                        onBlur: handleBlur,
                        name: "gst_file",
                      }}
                      extraArguments={{
                        upload_type: "gst_file",
                        vendor_profile_id: `${businessId}`,
                      }}
                      eletype={inputType.file}
                      value={values.gst_file}
                      errorText={touched.gst_file && errors.gst_file}
                      disabled={
                        profileData.profile_stage ===
                        "sent_for_accounts_approval"
                          ? true
                          : false
                      }
                    />
                  </Grid>

                  <Grid item md={6} p={3}>
                    <Element
                      label="Aadhar Number"
                      inputProps={{
                        type: "number",
                        placeholder: "Enter Aadhar Number",
                        onChange: handleChange,
                        onBlur: handleBlur,
                        name: "aadhaar_number",
                      }}
                      eletype={inputType.input}
                      value={values.aadhaar_number}
                      errorText={
                        touched.aadhaar_number && errors.aadhaar_number
                      }
                    />
                  </Grid>

                  <Grid item md={6} p={3}>
                    <Element
                      label="Upload Aadhar file"
                      inputProps={{
                        type: "text",

                        onChange: handleChange,
                        onBlur: handleBlur,
                        name: "aadhaar_file",
                      }}
                      extraArguments={{
                        upload_type: "aadhaar_file",
                        vendor_profile_id: `${businessId}`,
                      }}
                      eletype={inputType.file}
                      value={values.aadhaar_file}
                      errorText={touched.aadhaar_file && errors.aadhaar_file}
                    />
                  </Grid>
                  <Grid item md={6} p={3}>
                    <Element
                      label="COC Number"
                      inputProps={{
                        type: "text",
                        placeholder: "Enter Coc Number",
                        onChange: handleChange,
                        onBlur: handleBlur,
                        name: "incorporation_number",
                      }}
                      eletype={inputType.input}
                      value={values.incorporation_number}
                      errorText={
                        touched.incorporation_number &&
                        errors.incorporation_number
                      }
                    />
                  </Grid>
                  <Grid item md={6} p={3}>
                    <Element
                      label="Upload COC file"
                      inputProps={{
                        type: "text",

                        onChange: handleChange,
                        onBlur: handleBlur,
                        name: "incorporation_file",
                      }}
                      extraArguments={{
                        upload_type: "incorporation_file",
                        vendor_profile_id: `${businessId}`,
                      }}
                      eletype={inputType.file}
                      value={values.incorporation_file}
                      errorText={
                        touched.incorporation_file && errors.incorporation_file
                      }
                    />
                  </Grid>

                  <Grid item md={6} sx={{ marginTop: "23px" }}>
                    <Element
                      eletype={inputType.switch}
                      label="is MSME available ?"
                      inputProps={{
                        name: "maseAvailableSwitch",
                        onChange: (e) => {
                          setMSMESwitch(e.target.value);
                          handleChange(e);
                        },
                        handleBlur: handleBlur,
                      }}
                      value={values.maseAvailableSwitch}
                      errorText={
                        touched.maseAvailableSwitch &&
                        errors.maseAvailableSwitch
                      }
                    />
                  </Grid>

                  {/* <Grid item md={6} p={3}>
                    <Element
                      label="Aadhar Number"
                      inputProps={{
                        type: "text",
                        placeholder: "Enter Aadhar Number",
                        onChange: handleChange,
                        onBlur: handleBlur,
                        name: "aadhaar_number",
                      }}
                      eletype={inputType.input}
                      value={values.aadhaar_number}
                      errorText={
                        touched.aadhaar_number && errors.aadhaar_number
                      }
                    />
                  </Grid> */}

                  {values.maseAvailableSwitch === "on" ? (
                    <Grid item md={6} sx={{ marginTop: "23px" }}>
                      <Element
                        eletype={inputType.select}
                        label="Select MSME Type"
                        options={[
                          { value: "mirco", label: "Micro" },
                          { value: "mini", label: "Mini" },
                        ]}
                        inputProps={{
                          name: "msme_type",
                          onChange: handleChange,
                          handleBlur: handleBlur,
                        }}
                        value={values.msme_type}
                        errorText={touched.msme_type && errors.msme_type}
                      />
                    </Grid>
                  ) : (
                    <Grid item md={6} p={3}>
                      <Element
                        label="Udyog Aadhar"
                        inputProps={{
                          type: "text",
                          placeholder: "Enter Udyog Number",
                          onChange: handleChange,
                          onBlur: handleBlur,
                          name: "udyog_number",
                        }}
                        eletype={inputType.input}
                        value={values.udyog_number}
                        errorText={touched.udyog_number && errors.udyog_number}
                      />
                    </Grid>
                  )}

                  {values.maseAvailableSwitch === "on" ? (
                    <Grid item md={6} p={3}>
                      <Element
                        label=" MSME Registration Number"
                        inputProps={{
                          type: "text",
                          placeholder: "Enter MSME Registration Number",
                          onChange: handleChange,
                          onBlur: handleBlur,
                          name: "msme_reg_number",
                        }}
                        eletype={inputType.input}
                        value={values.msme_reg_number}
                        errorText={
                          touched.msme_reg_number && errors.msme_reg_number
                        }
                        disabled={
                          profileData.profile_stage ===
                          "sent_for_accounts_approval"
                            ? true
                            : false
                        }
                      />
                    </Grid>
                  ) : (
                    <></>
                  )}

                  {values.maseAvailableSwitch === "on" ? (
                    <Grid item md={6} p={3}>
                      <Element
                        label="Upload MSME Registration file"
                        inputProps={{
                          type: "text",

                          onChange: handleChange,
                          onBlur: handleBlur,
                          name: "msme_reg_file",
                        }}
                        extraArguments={{
                          upload_type: "msme_reg_file",
                          vendor_profile_id: `${businessId}`,
                        }}
                        eletype={inputType.file}
                        value={values.msme_reg_file}
                        errorText={
                          touched.msme_reg_file && errors.msme_reg_file
                        }
                        disabled={
                          profileData.profile_stage ===
                          "sent_for_accounts_approval"
                            ? true
                            : false
                        }
                      />
                    </Grid>
                  ) : (
                    <></>
                  )}

                  <Grid item md={6} p={3}>
                    <Element
                      label="NDA"
                      inputProps={{
                        type: "text",

                        onChange: handleChange,
                        onBlur: handleBlur,
                        name: "nda_file",
                      }}
                      extraArguments={{
                        upload_type: "nda_file",
                        vendor_profile_id: `${businessId}`,
                      }}
                      eletype={inputType.file}
                      value={values.nda_file}
                      errorText={touched.nda_file && errors.nda_file}
                      disabled={
                        profileData.profile_stage ===
                        "sent_for_accounts_approval"
                          ? true
                          : false
                      }
                    />
                  </Grid>

                  <Grid item md={6} sx={{ marginTop: "23px" }}>
                    <Element
                      eletype={inputType.switch}
                      label="Verified"
                      inputProps={{
                        name: "nda_file_verified",
                        onChange: handleChange,
                        handleBlur: handleBlur,
                      }}
                      value={values.nda_file_verified}
                      errorText={
                        touched.nda_file_verified && errors.nda_file_verified
                      }
                    />
                  </Grid>

                  <Grid item md={6} p={3}>
                    <Element
                      label=" Upload Office Adderess Proof"
                      inputProps={{
                        type: "text",

                        onChange: handleChange,
                        onBlur: handleBlur,
                        name: "address_proof",
                      }}
                      extraArguments={{
                        upload_type: "address_proof",
                        vendor_profile_id: `${businessId}`,
                      }}
                      eletype={inputType.file}
                      value={values.address_proof}
                      errorText={touched.address_proof && errors.address_proof}
                    />
                  </Grid>

                  <Grid item md={6} p={3}>
                    <Element
                      label="PF Challan"
                      inputProps={{
                        type: "text",
                        onChange: handleChange,
                        onBlur: handleBlur,
                        name: "pfchallanFile",
                      }}
                      extraArguments={{
                        upload_type: "pf_challan_file",
                        vendor_profile_id: `${businessId}`,
                      }}
                      eletype={inputType.file}
                      value={values.pfchallanFile}
                    />
                  </Grid>

                  <Grid item md={12} p={3}>
                    <Button
                      type="submit"
                      variant="contained"
                      sx={{ float: "right" }}
                    >
                      Save
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Card>
            {/* <BuisnessVerificationLoader /> */}
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
}
