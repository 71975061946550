import React, { useEffect, useMemo, useState } from "react";
import {
  Container,
  Stack,
  Typography,
  Grid,
  Box,
  Tabs,
  Tab,
  Badge,
  Tooltip,
} from "@mui/material";
import PersonalDetails from "./../../components/_dashboard/profile/PersonalDetails/PersonalDetails";
import EditPersonalDetails from "../../components/_dashboard/profile/PersonalDetails/EditPersonalDetails";
import Page from "../../components/Page";
// import BasicInfo from "./BasicInfo";
// import ProjectTable from "./ProjectTable";
// import BankDetails from "./BankDetails";
// import Business_Verification from "./Business_Verification";
// import ServiceOffered from "./ServiceOffered";
import { fetchVendorProfileDetailsThunk } from "../../store/slices/vendor/vendor.slice";
import { useDispatch, useSelector } from "react-redux";
import useBusinessId from "../../hooks/useBusinessId";
import { routesLink } from "./routesLink";
import { useLocation } from "react-router-dom";
import ProfileTabs from "./ProfileTabs";

function TabPanel(props) {
  const { children, value, index } = props;

  return (
    <Container sx={{ my: 4, mx: "auto" }}>
      {value === index ? children : null}
    </Container>
  );
}

const MyProfile = () => {
  const [profileEditMode, setProfileEditMode] = useState(false);
  const location = useLocation();
  const { resource_augmentation_service, profileData, turnover } = useSelector(
    ({ vendor }) => vendor.profileData
  );
  const [loading, setLoading] = useState(true);
  const [selectedTab, setSelectedTab] = useState(0);
  const [childSelectTab, setChildSelectTab] = useState(0);
  const businessId = useBusinessId();

  const handleProfileEditMode = (val) => {
    setProfileEditMode(val);
  };

  const resetEditMode = () => {
    setProfileEditMode(false);
  };

  const handleTabChange = (e, val) => {
    setSelectedTab(val);
    setChildSelectTab(0);
    resetEditMode();
  };

  const handleChildTabChange = (e, val) => {
    setChildSelectTab(val);
    resetEditMode();
  };

  const modifiedLabels = useMemo(() => {
    if (!resource_augmentation_service) {
      return routesLink.slice(0, routesLink.length - 1);
    }
    return routesLink;
  }, [resource_augmentation_service]);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchVendorProfileDetailsThunk()).then(() => {
      setLoading(false);
    });
  }, [dispatch]);

  const getIcon = (label, isActive) => {
    if (label) {
      const src = isActive ? label.activeIcon : label.icon;
      return (
        <img
          src={src}
          alt="icon"
          width="17"
          style={{ borderRadius: "0" }}
        />
      );
    }
    return null;
  };

  const [emptyFields, setEmptyFields] = useState([]);

  useEffect(() => {
    if (!loading && profileData) {
      const info = [];
      if (!profileData?.brand_name) info.push("brand name");
      if (!profileData?.company_type) info.push("company type");
      if (!profileData?.mobile_number) info.push("mobile");
      if (!profileData?.year_of_establishment) info.push("year of establishment");
      if (!profileData?.turnover) info.push("company turnover");
      if (!profileData?.number_of_employees) info.push("number of employees");
      if (!profileData?.number_of_clients) info.push("number of clients");
      if (!profileData?.website) info.push("website");
      if (!profileData?.logo?.filename) info.push("business logo");
      if (!profileData?.activeProjectNumber) info.push("active projects");
      if (!profileData?.cmmiLevelSelect) info.push("cmmi level");
      if (!profileData?.description) info.push("description");
      if (turnover?.length < 1) info.push("financial");

      setEmptyFields(info);
    }
  }, [loading, profileData, turnover]);

  const GetTabData = (singleLink, i, isChild = false) => {
    const isActive = isChild ? childSelectTab === i : selectedTab === i;
    return (
      <Tab
        key={i}
        label={
          // <Tooltip
          //   title={
          //     singleLink.label === "Info" ? (
          //       <div style={{ padding: "0 1rem" }}>
          //         <h3>Following data needs to be submitted:</h3>
          //         <hr />
          //         <ul>
          //           {emptyFields.map((field) => (
          //             <li key={field}>{field}</li>
          //           ))}
          //         </ul>
          //       </div>
          //     ) : (
          //       singleLink.tooltip || "Some Details have not been filled in yet"
          //     )
          //   }
          //   placement="bottom"
          //   arrow
          // >
          //   <Badge badgeContent="!" color="error">
              singleLink.label
          //   </Badge>
          // </Tooltip>
        }
        icon={getIcon(singleLink, isActive)}
        iconPosition="start"
      />
    );
  };

  return (
    <Page title="Profile | EMB">
      <Container sx={{ margin: 0 }}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            My Profile
          </Typography>
        </Stack>
        <Grid>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              variant="scrollable"
              allowScrollButtonsMobile
              value={selectedTab}
              onChange={handleTabChange}
              aria-label="basic tabs example"
            >
              {modifiedLabels.map((singleLink, i) =>
                singleLink.childTabs ? (
                  <Tab
                    key={i}
                    label={singleLink.label}
                    icon={getIcon(singleLink, false)}
                    iconPosition="start"
                  />
                ) : (
                  GetTabData(singleLink, i)
                )
              )}
            </Tabs>
            {modifiedLabels[selectedTab]?.childTabs && (
              <Tabs
                variant="scrollable"
                allowScrollButtonsMobile
                value={childSelectTab}
                onChange={handleChildTabChange}
                aria-label="basic tabs example"
              >
                {modifiedLabels[selectedTab].childTabs.map((singleLink, i) =>
                  GetTabData(singleLink, i, true)
                )}
              </Tabs>
            )}
          </Box>
          <>
            {modifiedLabels[selectedTab]?.childTabs
              ? modifiedLabels[selectedTab].childTabs.map((route, index) => (
                  <TabPanel value={childSelectTab} index={index} key={index}>
                    {route.components}
                  </TabPanel>
                ))
              : modifiedLabels.map((route, index) => (
                  <TabPanel value={selectedTab} index={index} key={index}>
                    {route.components}
                  </TabPanel>
                ))}
          </>
        </Grid>
      </Container>
    </Page>
  );
};

export default MyProfile;
