import React, { useEffect, useState } from "react";
import { Box, Grid, Button } from "@mui/material";
import "../../pages/Profile/upload.css";
import { portfolioValidation } from "../../utils/validations/vendor.validation";
import Element from "../../components/Form/Element";
import { inputType } from "../../utils/enum";
import { useDispatch, useSelector } from "react-redux";
// import {
//   createPastworkThunk,
//   fetchTechDataThunk,
//   updatePortfolioThunk,
// } from "../../store/slices/portfolio/portfolio.slice";
import { useFormik } from "formik";
// asdfa
import {
  backendOptions,
  budgetOptions,
  clientPersonaOptions,
  cloudOptions,
  databaseOptions,
  frontendOptions,
  platformOptions,
} from "../../utils/options.utils";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Icon } from "@iconify/react";
import plusFill from "@iconify/icons-eva/plus-fill";
import {
  createPastworkThunk,
  fetchTechDataThunk,
  updatePastworkThunk,
} from "../../store/slices/vendor/vendor.slice";
import { updatePortfolioThunk } from "../../store/slices/portfolio/portfolio.slice";
import {
  fetchAllResourceBiFurcationList,
  fetchResourceBiferctionList,
  fetchSubIndustry,
} from "../../utils/server.util";
import useProductCodeData from "../../hooks/useProductCodeData";

const INIT_STATE = {
  client_name: "",
  // product_code: "",
  service: "",
  industry: "",
  sub_industry: "",
  delivery_class: "",
  description: "",
  client_persona: "",
  project_budget: "",
  meta_tags: "",
  own_source_code: "off",
  key_client: "off",
  links_details: [],
};

const RoleModal = ({
  selectedData,
  handleClose,
  productList,
  page,
  backendOptions,
  frontendOptions,
  databaseOptions,
  cloudOptions,
}) => {
  const dispatch = useDispatch();
  // console.log("selectedData: ", selectedData);
  // const { frontend, backend } = useSelector((vendor) => vendor.vendor);

  // console.log("keshav", backendList, frontendList, database, cloud);

  // useEffect(() => {
  //   dispatch(fetchTechDataThunk("frontend"));
  //   dispatch(fetchTechDataThunk("backend"));
  // }, [dispatch]);

  const { services, industries, deliveryClass } = useProductCodeData();

  const [subIndustriesList, setSubIndutries] = useState([]);

  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues: selectedData
      ? {
          ...selectedData,
          service: {
            ...selectedData.service,
            service_id: selectedData.service?._id,
          },
          industry: selectedData.industries,
          verified: selectedData.verified ? "on" : "off",
          service: selectedData?.service_id,
          industry: selectedData?.industry_id,
          sub_industry: selectedData?.sub_industry_id,
          delivery_class: selectedData?.delivery_class_id,
        }
      : INIT_STATE,
    onSubmit: onSubmit,
    validationSchema: portfolioValidation,
  });

  const [additinalLinks, setAdditionallinks] = useState(values?.links_details);

  useEffect(() => {
    setAdditionallinks(values?.links_details);
  }, [values?.links_details]);

  const deleteAdditionalLinks = (ind) => {
    setAdditionallinks(values?.links_details.filter((link, i) => i !== ind));
    setFieldValue(
      "links_details",
      values?.links_details.filter((link, i) => i !== ind)
    );
  };

  function formatLinks(links) {
    return links.map((link) => {
      return {
        ...link,
        backend: (function () {
          const obj = backendOptions.find((ele) => ele.value === link.backend);
          return {
            id: obj.value,
            name: obj.label,
          };
        })(),
        frontend: (function () {
          const obj = frontendOptions.find(
            (ele) => ele.value === link.frontend
          );
          return {
            id: obj.value,
            name: obj.label,
          };
        })(),
        database: (function () {
          const obj = databaseOptions.find(
            (ele) => ele.value === link.database
          );
          return {
            id: obj.value,
            name: obj.label,
          };
        })(),
        cloud: (function () {
          const obj = cloudOptions.find((ele) => ele.value === link.cloud);
          return {
            id: obj.value,
            name: obj.label,
          };
        })(),
      };
    });
  }

  function onSubmit(data) {
    // debugger
    data.links = formatLinks(data.links_details);

    var new_data = {
      service_id: data.service.id ?? data.service,
      industry_id: data.industry.id ?? data.industry,
      sub_industry_id: data.sub_industry.id ?? data.sub_industry,
      delivery_class_id: data.delivery_class.id ?? data.delivery_class,
    };

    const payload = {
      data: {
        ...data,
        ...new_data,
        own_source_code: data.own_source_code === "on" ? true : false,
        key_client: data.key_client === "on" ? true : false,
      },
    };

    //removing the keys from the object
    delete payload.data.service;
    delete payload.data.delivery_class;
    delete payload.data.industry;
    delete payload.data.sub_industry;

    if (selectedData) {
      return dispatch(
        // updatePortfolioThunk({
        //   id: selectedData.id,
        //   data: { ...data, verified: data.verified === "on" ? true : false },
        // })
        updatePastworkThunk({
          id: selectedData._id,
          data: { ...payload, verified: data.verified === "on" ? true : false },
        })
      )
        .unwrap()
        .then(handleClose);
    }
    dispatch(
      createPastworkThunk({
        data: payload,
      })
    )
      .unwrap()
      .then(handleClose);
  }

  useEffect(async () => {
    if (values.industry?.id) {
      // When values is already present (like, when clicked on edit)
      const subIndustriesList = await fetchSubIndustry(values.industry?.id);
      // console.log("Fetched Sub Industries: ", subIndustriesList);
      setSubIndutries(subIndustriesList);
      if (selectedData.sub_industry_id) {
        setFieldValue("sub_industry", selectedData.sub_industry_id);
      }
    } else if (values.industry) {
      const subIndustriesList = await fetchSubIndustry(values.industry);
      // console.log("Fetched Sub Industries2222: ", subIndustriesList);
      setSubIndutries(subIndustriesList);
      if (selectedData.sub_industry_id) {
        setFieldValue("sub_industry", selectedData.sub_industry_id);
      }
    }

    // debugger;
    if (selectedData.industry_id === values.industry) {
      setFieldValue("sub_industry", selectedData.sub_industry_id);
      // console.log("values changed", selectedData.sub_industry_id);
    }
  }, [values.industry]);

  console.log(values, "values");

  const addInputRow = async () => {
    setFieldValue("links_details", [
      ...values.links_details,
      {
        link: "",
        backend: "",
        frontend: "",
        database: "",
        cloud: "",
        demo_link: "",
        link_creds: "",
        platform: "",
      },
    ]);
  };

  return (
    <>
      <Box>
        <form o>
          <Grid container spacing={0} p={2}>
            <Grid item md={12} pb={0}>
              <Element
                label="Client Name"
                inputProps={{
                  type: "text",
                  placeholder: "Enter Client Name",
                  onChange: handleChange,
                  onBlur: handleBlur,
                  name: "client_name",
                }}
                eletype={inputType.input}
                value={values.client_name}
                errorText={touched.client_name && errors.client_name}
              />
            </Grid>
            {/* removing the product code as per requirment */}
            {/* <Grid item md={12} pb={0}>
              <Element
                label="Product Code"
                inputProps={{
                  type: "select",
                  placeholder: "Enter Product Code",
                  onChange: handleChange,
                  onBlur: handleBlur,
                  name: "product_code",
                }}
                eletype={inputType.select}
                options={productList}
                value={values.product_code}
                // errorText={
                //   touched.product_code &&
                //   touched.product_code?.id &&
                //   errors.product_code &&
                //   errors.product_code?.id
                // }
              />
            </Grid> */}
            <Grid item md={12} pb={0}>
              <Element
                inputProps={{
                  name: "service",
                  onChange: handleChange,
                  onBlur: handleBlur,
                  placeholder: "Select Service",
                }}
                label="Select Service"
                placeholder="Select Service"
                value={values.service}
                errorText={touched.service && errors.service}
                name="service"
                options={services}
                eletype={inputType.select}
              />
            </Grid>
            <Grid item md={12} pb={0}>
              <Element
                inputProps={{
                  name: "industry",
                  onChange: handleChange,
                  onBlur: handleBlur,
                  placeholder: "Select Industry",
                }}
                label="Select Industry"
                placeholder="Select Industry"
                value={values.industry}
                errorText={touched.industry && errors.industry}
                name="service"
                options={industries}
                eletype={inputType.select}
              />
            </Grid>
            {subIndustriesList && (
              <Grid item md={12} pb={0}>
                <Element
                  inputProps={{
                    name: "sub_industry",
                    onChange: handleChange,
                    onBlur: handleBlur,
                    placeholder: "Select Sub Industry",
                  }}
                  label="Select Sub Industry"
                  placeholder="Select Sub Industry"
                  value={values.sub_industry}
                  errorText={touched.sub_industry && errors.sub_industry}
                  name="service"
                  options={subIndustriesList[0]?.children}
                  eletype={inputType.select}
                  resetTheField={values.industry?.id ? false : true}
                />
              </Grid>
            )}
            <Grid item md={12} pb={0}>
              <Element
                inputProps={{
                  name: "delivery_class",
                  onChange: handleChange,
                  onBlur: handleBlur,
                  placeholder: "Select Delivery Class",
                }}
                label="Select Delivery Class"
                placeholder="Select Delivery Class"
                value={values.delivery_class}
                errorText={touched.delivery_class && errors.delivery_class}
                name="delivery_class"
                options={deliveryClass}
                eletype={inputType.select}
              />
            </Grid>
            <Grid item md={12} pb={0}>
              <Element
                label="Description"
                inputProps={{
                  type: "text",
                  placeholder: "Enter Description",
                  onChange: handleChange,
                  onBlur: handleBlur,
                  name: "description",
                }}
                eletype={inputType.input}
                value={values.description}
                errorText={touched.description && errors.description}
              />
            </Grid>
            <Grid item md={12}>
              <Element
                label="Client Persona"
                inputProps={{
                  name: "client_persona",
                  onChange: handleChange,
                  onBlur: handleBlur,
                }}
                value={values.client_persona}
                options={clientPersonaOptions}
                errorText={touched.client_persona && errors.client_persona}
                eletype={inputType.select}
                icons={
                  <ArrowDropDownIcon
                    sx={{ position: "absolute", right: 20, bottom: 24 }}
                  />
                }
              />
            </Grid>

            <Grid item md={12} pb={0}>
              <Element
                label="Project Budget"
                inputProps={{
                  type: "text",
                  placeholder: "Enter Project Budget",
                  onChange: handleChange,
                  onBlur: handleBlur,
                  name: "project_budget",
                }}
                eletype={inputType.select}
                options={budgetOptions}
                value={values.project_budget}
              />
            </Grid>

            <Grid item md={12} pb={3}>
              <Element
                label="Meta Tags"
                inputProps={{
                  placeholder: "Enter Meta Tags",
                  type: "text",
                  onChange: handleChange,
                  onBlur: handleBlur,
                  name: "meta_tags",
                }}
                eletype={inputType.chip}
                value={values.meta_tags}
              />
            </Grid>
            <Grid item md={12} sx={{ display: "flex", alignItems: "center" }}>
              <Element
                eletype={inputType.switch}
                label="If you own Source Code?"
                inputProps={{
                  name: "own_source_code",
                  onChange: handleChange,
                  handleBlur: handleBlur,
                }}
                value={values.own_source_code}
              />
            </Grid>

            <Grid item md={12} sx={{ display: "flex", alignItems: "center" }}>
              <Element
                eletype={inputType.switch}
                label="Key Client"
                inputProps={{
                  name: "key_client",
                  onChange: handleChange,
                  handleBlur: handleBlur,
                }}
                value={values.key_client}
              />
            </Grid>
            <Grid item md={12} pb={2}>
              <Button
                size="large"
                variant="contained"
                startIcon={<Icon icon={plusFill} />}
                onClick={addInputRow}
              >
                Add Link
              </Button>
            </Grid>

            {additinalLinks?.map((item, index) => (
              <div
                style={{
                  // border: '1px solid gray',
                  width: "100%",
                  padding: "20px 40px",
                  borderRadius: "8px",
                  boxShadow: "0px 0px 10px gray",
                  margin: "30px auto",
                  position: "relative",
                }}
              >
                <div
                  style={{
                    position: "absolute",
                    top: "5px",
                    right: "5px",
                    width: "30px",
                    height: "30px",
                    borderRadius: "8px",
                    backgroundColor: "#f05f5f",
                    color: "white",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    cursor: "pointer",
                    fontSize: "18px",
                    fontWeight: "bold",
                    boxShadow: "0px 0px 10px gray",
                  }}
                  onClick={() => deleteAdditionalLinks(index)}
                >
                  x
                </div>
                <Grid item md={12} pb={2}>
                  <Element
                    label="Link"
                    inputProps={{
                      type: "text",
                      placeholder: "Enter Link",
                      onChange: handleChange,
                      onBlur: handleBlur,
                      name: `links_details.${index}.link`,
                    }}
                    eletype={inputType.url}
                    value={item.link}
                  />
                </Grid>

                <Grid item md={12}>
                  <Element
                    label="Platform"
                    inputProps={{
                      name: `links_details.${index}.platform`,
                      onChange: handleChange,
                      onBlur: handleBlur,
                    }}
                    value={item.platform}
                    options={platformOptions}
                    eletype={inputType.select}
                    icons={
                      <ArrowDropDownIcon
                        sx={{ position: "absolute", right: 20, bottom: 24 }}
                      />
                    }
                  />
                </Grid>

                <Grid item md={12}>
                  <Element
                    label="Backend"
                    inputProps={{
                      name: `links_details.${index}.backend`,
                      onChange: handleChange,
                      onBlur: handleBlur,
                    }}
                    value={item.backend}
                    options={backendOptions}
                    errorText={
                      touched.links_details &&
                      touched.links_details[index] &&
                      errors.links_details &&
                      errors.links_details[index]?.backend
                    }
                    eletype={inputType.select}
                    icons={
                      <ArrowDropDownIcon
                        sx={{ position: "absolute", right: 20, bottom: 24 }}
                      />
                    }
                  />
                </Grid>

                <Grid item md={12}>
                  <Element
                    label="Frontend"
                    inputProps={{
                      name: `links_details.${index}.frontend`,
                      onChange: handleChange,
                      onBlur: handleBlur,
                    }}
                    value={item.frontend}
                    options={frontendOptions}
                    errorText={
                      touched.links_details &&
                      touched.links_details[index] &&
                      errors.links_details &&
                      errors.links_details[index]?.frontend
                    }
                    eletype={inputType.select}
                    icons={
                      <ArrowDropDownIcon
                        sx={{ position: "absolute", right: 20, bottom: 24 }}
                      />
                    }
                  />
                </Grid>

                <Grid item md={12}>
                  <Element
                    label="Database"
                    inputProps={{
                      name: `links_details.${index}.database`,
                      onChange: handleChange,
                      onBlur: handleBlur,
                    }}
                    value={item.database}
                    options={databaseOptions}
                    errorText={
                      touched.links_details &&
                      touched.links_details[index] &&
                      errors.links_details &&
                      errors.links_details[index]?.database
                    }
                    eletype={inputType.select}
                    icons={
                      <ArrowDropDownIcon
                        sx={{ position: "absolute", right: 20, bottom: 24 }}
                      />
                    }
                  />
                </Grid>

                <Grid item md={12}>
                  <Element
                    label="Cloud"
                    inputProps={{
                      name: `links_details.${index}.cloud`,
                      onChange: handleChange,
                      onBlur: handleBlur,
                    }}
                    value={item.cloud}
                    options={cloudOptions}
                    errorText={
                      touched.links_details &&
                      touched.links_details[index] &&
                      errors.links_details &&
                      errors.links_details[index]?.cloud
                    }
                    eletype={inputType.select}
                    icons={
                      <ArrowDropDownIcon
                        sx={{ position: "absolute", right: 20, bottom: 24 }}
                      />
                    }
                  />
                </Grid>

                <Grid item md={12}>
                  <Element
                    label="Demo Link"
                    inputProps={{
                      type: "text",
                      placeholder: "Enter Demo Link",
                      onChange: handleChange,
                      onBlur: handleBlur,
                      name: `links_details.${index}.demo_link`,
                    }}
                    eletype={inputType.url}
                    value={item.demo_link}
                  />
                </Grid>

                <Grid item md={12}>
                  <Element
                    label="Link Credentials"
                    inputProps={{
                      type: "text",
                      placeholder: "Enter Link Credentials",
                      onChange: handleChange,
                      onBlur: handleBlur,
                      name: `links_details.${index}.link_creds`,
                    }}
                    eletype={inputType.input}
                    value={item.link_creds}
                  />
                </Grid>
              </div>
            ))}

            <Grid item md={12}>
              <Button
                size="large"
                variant="contained"
                type="submit"
                onClick={handleSubmit}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
    </>
  );
};

export default RoleModal;
