import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Grid, Button, Stack, IconButton } from "@mui/material";
import { inputType } from "../../utils/enum";
import {
  createPOCThunk,
  updatePOCThunk,
} from "../../store/slices/vendor/vendor.slice";
import { escalationLevel } from "../../utils/options.utils";
import Element from "../../components/Form/Element";
import MobileInput from "../../components/Form/MobileInput";
import "react-phone-input-2/lib/style.css";
import { useFormik } from "formik";
import { escalationLevelValidations } from "../../utils/validations/vendor.validation";

export default function PointOfContactModal({ props, closeModal, page }) {
  const dispatch = useDispatch();

  function onSubmit(data) {
    const past_data = {
      name: props.name,
      email: props.email,
      // escalation_level: props.escalation_level,
      designation: props.designation,
      code: props.code,
      mobile_number: props.mobile_number,
    };
    const newData = {
      ...data,
      code: data?.mobile?.country_code,
      mobile_number: data?.mobile?.mobile_number,
    };
    delete newData?.mobile;

    if (!props) {
      dispatch(
        createPOCThunk({
          data: newData,
          page: page,
        })
      )
        .unwrap()
        .then(closeModal);
    } else {
      dispatch(
        updatePOCThunk({
          id: props._id,
          data: newData,
          // past_data: past_data
        })
      )
        .unwrap()
        .then(closeModal);
    }
  }

  const { values, errors, touched, handleChange, handleBlur, handleSubmit } =
    useFormik({
      initialValues: {
        name: props.name === "" ? "" : props.name,
        email: props.email === "" ? "" : props.email,
        // escalation_level:
        //   props.escalation_level === "" ? "" : props.escalation_level,
        designation: props.designation === "" ? "" : props.designation,
        mobile: {
          country_code: props?.code,
          mobile_number: props?.mobile_number,
        },
      },
      onSubmit: onSubmit,
      validationSchema: escalationLevelValidations,
    });

  return (
    <>
      <Box>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2} p={2}>
            <Grid item md={6} pb={0}>
              <Element
                label="Name"
                inputProps={{
                  placeholder: "Enter First Name",
                  type: "text",
                  onChange: handleChange,
                  onBlur: handleBlur,
                  name: "name",
                }}
                eletype={inputType.input}
                value={values.name}
                errorText={touched.name && errors.name}
              />
            </Grid>
            <Grid item md={6} pb={0}>
              <MobileInput
                name="mobile"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.mobile}
                errorText={touched.mobile && errors.mobile?.mobile_number}
                label="Contact Number"
              />
            </Grid>
            <Grid item md={6} pb={0}>
              <Element
                label="Email Id"
                inputProps={{
                  placeholder: "Enter Email ID",
                  type: "text",
                  onChange: handleChange,
                  onBlur: handleBlur,
                  name: "email",
                }}
                eletype={inputType.input}
                value={values.email}
                errorText={touched.email && errors.email}
              />
            </Grid>
            <Grid item md={6} pb={0}>
              <Element
                label="Designation"
                inputProps={{
                  placeholder: "Enter  Designation",
                  type: "text",
                  onChange: handleChange,
                  onBlur: handleBlur,
                  name: "designation",
                }}
                eletype={inputType.input}
                value={values.designation}
                errorText={touched.designation && errors.designation}
              />
            </Grid>
            {/* <Grid item md={6} pb={0}>
              <Element
                label="Escalation Level"
                inputProps={{
                  name: "escalation_level",
                  onChange: handleChange,
                  onBlur: handleBlur,
                }}
                value={values.escalation_level}
                options={escalationLevel}
                errorText={touched.escalation_level && errors.escalation_level}
                eletype={inputType.select}
              />
            </Grid> */}
            <Grid item md={12} p={3} sx={{ textAlign: "center" }}>
              <Button type="submit" variant="contained">
                Submit
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
    </>
  );
}
