import React, { useEffect, useState } from "react";
import {
  getResouceBifercationList,
  getTechStackList,
} from "../../utils/server.util";
import Element from "../../components/Form/Element";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { inputType } from "../../utils/enum";
import { Grid } from "@mui/material";
import { capitalCase } from "change-case";

function ResourceBifercationAndTeckStack({
  handleChange = () => {},
  handleBlur = () => {},
  values = {},
  touched = {},
  errors = {},
  index,
  item,
  getAllTechStackList,
}) {
  const [resourceBifercation, setResourceBifercation] = useState([]);
  const [techStack, setTechStack] = useState([]);
  const [selectedResource, setSelectedResource] = useState(null);
  const [resourceChange, setresourceChange] = useState(false);

  useEffect(() => {
    setSelectedResource(item.resource_bifurcation_id);
  }, [item.resource_bifurcation_id]);

  useEffect(() => {
    if (item.tech_stack_id) {
      handleChange({
        target: {
          name: `techStack.${index}.tech_stack`,
          value: item.tech_stack ?? item.tech_stack_id,
        },
      });
    }
  }, [item.tech_stack]);

  useEffect(() => {
    getAllTechStackList(techStack);
  }, [techStack]);

  useEffect(() => {
    (async function () {
      const rb = await getResouceBifercationList();
      setResourceBifercation(
        rb.map((r) => ({ label: capitalCase(r.name), value: r._id }))
      );
    })();
  }, []);

  useEffect(() => {
    if (selectedResource) {
      (async function () {
        const ts = await getTechStackList(selectedResource);
        setTechStack(ts.map((r) => ({ label: r.name, value: r._id })));
        handleChange({
          target: {
            name: `techStack.${index}.tech_stack`,
            value: null,
          },
        });
      })();
    }
  }, [selectedResource]);

  function handleResourceChange(e) {
    setSelectedResource(e.target.value);
    setresourceChange(true);
  }

  return (
    <>
      <Grid container md={12} spacing={2}>
        <Grid item md={6}>
          <Element
            label="Resouce Bifuercation"
            inputProps={{
              name: "tech_stack_options",
              onChange: handleResourceChange,
              onBlur: handleBlur,
            }}
            value={selectedResource}
            options={resourceBifercation}
            errorText={touched.tech_stack_options && errors.tech_stack_options}
            eletype={inputType.select}
            icons={
              <ArrowDropDownIcon
                sx={{
                  position: "absolute",
                  right: 20,
                  bottom: 24,
                }}
              />
            }
          />
        </Grid>
        {selectedResource && (
          <Grid item md={6}>
            <Element
              label="Tech Stack"
              inputProps={{
                name: `techStack.${index}.tech_stack`,
                onChange: handleChange,
                onBlur: handleBlur,
              }}
              value={values.tech_stack}
              options={techStack}
              errorText={
                touched.techStack &&
                touched.techStack[index] &&
                errors.techStack &&
                errors.techStack[index]?.tech_stack
              }
              eletype={inputType.select}
              icons={
                <ArrowDropDownIcon
                  sx={{
                    position: "absolute",
                    right: 20,
                    bottom: 24,
                  }}
                />
              }
            />
          </Grid>
        )}
      </Grid>
    </>
  );
}

export default ResourceBifercationAndTeckStack;
