import {
  Box,
  Button,
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  IconButton,
  TablePagination,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Modal from "../../components/Modal/Modal";
import Scrollbar from "../../components/common/Scrollbar";
import SearchNotFound from "../../components/NotFound/SearchNotFound";
import plusFill from "@iconify/icons-eva/plus-fill";
import { Icon } from "@iconify/react";
//   import BenchStrength from "./BenchStrenght";
import { useDispatch, useSelector } from "react-redux";
//   import { deleteBenchStrengthThunk, fetchBenchInfoThunk } from "../store/slices/vendor/vendor.slice";
import EditIcon from "@mui/icons-material/Edit";
import DeleteAction from "../../components/common/DeleteAction";
import BenchStrength from "./BenchStrength";
import {
  deleteBenchStrengthThunk,
  fetchBenchInfoThunk,
} from "../../store/slices/vendor/vendor.slice";

import { capitalCase } from "change-case";

const TABLE_HEAD = [
  { label: "Candidate Name", id: "candidate_name", alignRight: false },
  {
    label: "Resource Bifurcation",
    id: "resource_bifurcation",
    alignRight: false,
  },
  { label: "Experience", id: "experience", alignRight: false },
  { label: "Tech Stack", id: "tech_stack", alignRight: false },
  { label: "Team Model", id: "team_model", alignRight: false },
  { label: "Cost Per Month", id: "cost_per_month", alignRight: false },
  { label: "Location", id: "locations", alignRight: false },
  { label: "Action", id: "action", alignRight: false },
];

const BenchStrengthTableForm = () => {
  const [showModal, setShowModal] = useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage] = React.useState(10);
  const [singleBenchStrengthDataInfo, setSingleBenchStrengthDataInfo] =
    useState(null);
  const dispatch = useDispatch();
  const handleModalPhase = () => {
    setShowModal(!showModal);
  };
  const handleEdit = (row) => {
    setShowModal(true);
    setSingleBenchStrengthDataInfo(row);
  };
  const { benchStrengthInfoData, totalbenchStrength } = useSelector(
    ({ vendor }) => vendor
  );

  console.log(
    "benchStrengthInfoData: ",
    benchStrengthInfoData,
    totalbenchStrength
  );

  useEffect(() => {
    dispatch(fetchBenchInfoThunk());
  }, [dispatch]);

  const handleDelete = (id) => {
    dispatch(deleteBenchStrengthThunk({ id, page: 1 }));
    setPage(0);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSingleBenchStrengthDataInfo(null);
  };

  const handleChangePage = (_, newPage) => {
    dispatch(fetchBenchInfoThunk(newPage + 1));
    setPage(newPage);
  };

  return (
    <>
      <div style={{ height: 400, width: "100%" }}>
        <Box
          sx={{
            display: "flex",
            marginBottom: "30px",
            justifyContent: "end",
          }}
        >
          <Button
            variant="contained"
            onClick={handleModalPhase}
            startIcon={<Icon icon={plusFill} />}
          >
            Add Bench Strength
          </Button>
        </Box>

        <Box
          sx={{
            height: "100%",
            width: 1,
            "& .table-header": {
              backgroundColor: "rgb(244, 246, 248)",
              color: "rgb(99, 115, 129)",
            },
          }}
        >
          <Card>
            <Scrollbar>
              {benchStrengthInfoData.length > 0 ? (
                <TableContainer sx={{ minWidth: 800 }}>
                  <Table>
                    <TableHead sx={{ backgroundColor: "rgb(243, 246, 248)" }}>
                      {TABLE_HEAD.map((column) =>
                        column.id != "action" ? (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            style={{
                              top: 57,
                              minWidth: column.minWidth,
                              backgroundColor: "#f3f6f8",
                            }}
                          >
                            {column.label}
                          </TableCell>
                        ) : (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            style={{
                              minWidth: column.minWidth,
                              backgroundColor: "#f3f6f8",
                              position: "sticky",
                              right: 0,
                            }}
                          >
                            {column.label}
                          </TableCell>
                        )
                      )}
                    </TableHead>
                    <TableBody>
                      {benchStrengthInfoData?.length === 0
                        ? []
                        : benchStrengthInfoData?.map((row) => {
                            return (
                              <TableRow
                                hover
                                key={row.id}
                                tabIndex={-1}
                                role="checkbox"
                                sx={{ cursor: "pointer" }}
                              >
                                <TableCell
                                  onClick={() => handleEdit(row)}
                                  align="left"
                                >
                                  {capitalCase(row?.candidate_name || "") ||
                                    "NULL"}
                                </TableCell>
                                <TableCell
                                  onClick={() => handleEdit(row)}
                                  align="left"
                                >
                                  {capitalCase(
                                    row?.resource_bifurcation?.name || "-"
                                  )}
                                </TableCell>
                                <TableCell
                                  onClick={() => handleEdit(row)}
                                  align="left"
                                >
                                  {row?.experience}
                                </TableCell>
                                <TableCell
                                  onClick={() => handleEdit(row)}
                                  align="left"
                                >
                                  {capitalCase(row?.tech_stack?.name || "-")}
                                </TableCell>
                                <TableCell
                                  onClick={() => handleEdit(row)}
                                  align="left"
                                >
                                  {capitalCase(row?.team_model)}
                                </TableCell>
                                <TableCell
                                  onClick={() => handleEdit(row)}
                                  align="left"
                                >
                                  {row?.cost_per_month}
                                </TableCell>
                                <TableCell
                                  onClick={() => handleEdit(row)}
                                  align="left"
                                >
                                  {row?.locations?.map((item) => (
                                    <span
                                      style={{ margin: "5px", padding: "3px" }}
                                    >
                                      {item}
                                    </span>
                                  )) || "-"}

                                  {/* {row?.loactions?.join(', ')} */}
                                </TableCell>

                                <TableCell component="th" scope="row">
                                  <IconButton color="success" component="span">
                                    <EditIcon
                                      color="success"
                                      sx={{ cursor: "pointer" }}
                                      onClick={() => handleEdit(row)}
                                    />
                                  </IconButton>
                                  <IconButton color="error" component="span">
                                    <DeleteAction
                                      handleDelete={() => handleDelete(row.id)}
                                    />
                                  </IconButton>
                                </TableCell>
                              </TableRow>
                            );
                          })}
                    </TableBody>
                    <TableFooter>
                      <TableRow>
                        <TablePagination
                          count={totalbenchStrength}
                          rowsPerPage={rowsPerPage}
                          page={page}
                          onPageChange={handleChangePage}
                          rowsPerPageOptions={false}
                        />
                      </TableRow>
                    </TableFooter>
                  </Table>
                </TableContainer>
              ) : (
                <SearchNotFound
                  src="/static/support.svg"
                  subtitle="There is currently no data to show."
                />
              )}
            </Scrollbar>
          </Card>
        </Box>
      </div>

      <Modal
        isOpen={showModal}
        closeModal={handleCloseModal}
        title="Bench Strength Info"
        content={
          <BenchStrength
            closeModal={handleCloseModal}
            singleBenchStrengthDataInfo={singleBenchStrengthDataInfo}
          />
        }
        dialogProps={{ fullWidth: true }}
        titleStyle={{ textAlign: "center", marginTop: 2 }}
        actionStyle={{ justifyContent: "center", marginBottom: 2 }}
      />
    </>
  );
};

export default BenchStrengthTableForm;
