import * as Yup from "yup";

export const mobileValidation = Yup.object({
  mobile: Yup.object({
    mobile_number: Yup.string()
      .required("Mobile is Required")
      .max(10, "Please Enter a Valid Mobile Number")
      .min(10, "Please Enter a Valid Mobile Number"),
  }),
});

export const uploadPortfolioValidation = Yup.object({
  service: Yup.object({
    service_name: Yup.string().required("Service name is Required"),
  }),
  industry: Yup.array().min(1, "Industry is Required"),
  client_name: Yup.string().required("Client Name is required"),
});

export const uploadCVValidation = Yup.object({
  applicant_name: Yup.string().required("Name is required"),
  skill: Yup.string().required("Skill is required"),
  year_of_exp: Yup.number()
    .required("Year Of Experience is required")
    .typeError("Year Of Experience must be a number")
    .min(1, "Year Of Experience must be at least 1")
    .max(99, "Year Of Experience must be at most 99"),
  vendor_budget: Yup.string().required("Budget is required"),
});

export const businessProfileValidations = Yup.object({
  brand_name: Yup.string().required("Brand Name is required"),
  registered_business_name: Yup.string().required("Business Name is required"),
});

export const ticketValidations = Yup.object({
  title: Yup.string().required("Title is Required"),
  description: Yup.string().required("Description is Required"),
});

export const customServicesValidation = Yup.object({
  service_name: Yup.string().required("Service name is required"),
  minimum_price: Yup.string().required("Minimum price is required"),
  maximum_price: Yup.string().required("Maximum price is required"),
});

export const serviceValidation = Yup.object({
  minimum_price: Yup.string().required("Minimum price is required"),
  maximum_price: Yup.string().required("Maximum price is required"),
});

export const addressInfovalidation = Yup.object({
  address_line1: Yup.string().required("Address1 is required"),
  address_line2: Yup.string().required("Address2 is required"),
  city: Yup.string().required("City is required"),
  state: Yup.string().required("State is required"),
  country: Yup.string().required("Country is required"),
  zip_code: Yup.string().required("Pincode is required"),
});

export const bankDetailsValidation = Yup.object({
  bank_name: Yup.string().required("Bank name is required"),
  account_number: Yup.string().required("Account number is required"),
  ifsc_code: Yup.string().required("IFSC code is required"),
  bank_branch: Yup.string().required("Branch name is required"),
  cancelled_cheque_file: Yup.object({
    url: Yup.string().required("Cancelled Cheque is required"),
  }),
});

export const basicInfoValidations = Yup.object({
  brand_name: Yup.string().required("BrandName is required"),
  registered_business_name: Yup.string().required("Business Name is required"),
  company_type: Yup.string().required("Company Type is required"),
  mobile: Yup.object({
    mobile_number: Yup.string()
      .required("Mobile is required")
      .test(
        "len",
        "Please Enter a Valid Mobile Number",
        (val) => val.length === 10
      ),
  }),
  year_of_establishment: Yup.string().required(
    "Year of Establishment is required"
  ),
  turnover: Yup.string().required("TurnOver is required"),
  number_of_employees: Yup.string().required("Number of Employees is required"),
  number_of_clients: Yup.string().required("Number of Clients is required"),
  website: Yup.string().url("Please enter a Valid Url"),
  logo: Yup.object({
    url: Yup.string().required("Logo is required"),
  }),
  rating: Yup.string().required("Rating is required"),
  description: Yup.string().required("Description is required"),
});
